import React, { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { Heart } from 'react-bytesize-icons';
import { Square, Image, Type, Droplet } from 'react-feather';
import Img from 'gatsby-image';

const styles = require('./XDPlugin-styles.module.css');

const Columns = () => {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <section className={styles.columns}>
          <h1>
            <img className={styles.icon} src={data.icon.publicURL} alt="Superposition" />
            <Heart width={48} height={48} /> <img className={styles.icon} src={data.adobe.publicURL} alt="Figma" />
          </h1>
          <h2>Superposition plugin for Adobe XD</h2>

          <p>
            Use the design system you already have. <br />
            Extract design tokens from websites and use them directly in Adobe XD.
          </p>
          <img src={data.XDScreenshot.publicURL} alt="XD plugin with Superposition" />

          <p>
            Available for free on the Adobe XD plugin repository: <br />
            <a className={styles.button} href="https://xd.adobelanding.com/en/xd-plugin-download/?name=db30d108">
              Get Superposition for Adobe XD
            </a>
          </p>

          <div className={styles.row}>
            <div className={styles.item}>
              <Droplet width={48} height={48} />
              <h3>Colors</h3>
              <p>Add colors to assets or use them as fills.</p>
            </div>

            <div className={styles.item}>
              <Type width={48} height={48} />
              <h3>Typography</h3>
              <p>Transform typography into what's being used on the site.</p>
            </div>
            <div className={styles.item}>
              <Square width={48} height={48} />
              <h3>Border radius</h3>
              <p>Apply radius to rectangles and vectors.</p>
            </div>
            <div className={styles.item}>
              <Heart width={48} height={48} />
              <h3>Shadows</h3>
              <p>Use the same shadows as your site.</p>
            </div>
          </div>
        </section>
      )}
    />
  );
};

export const query = graphql`
  query {
    XDScreenshot: file(relativePath: { eq: "images/xdscreenshot.png" }) {
      publicURL
    }
    icon: file(relativePath: { eq: "images/icon.png" }) {
      publicURL
    }
    adobe: file(relativePath: { eq: "images/xd.svg" }) {
      publicURL
    }
  }
`;

export default Columns;
